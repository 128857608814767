<template>
    <div>
        <block v-if="loading"></block>
        <v-row class="mx-1 mt-2">
            <v-col cols="12" md="3">
                <v-btn @click="showAccountingForm = true" class="btn-add">Seleccionar cuenta contable a filtrar</v-btn>
            </v-col>
            <v-col cols="12" md="9">
                <h3 class="">Cuenta contable seleccionada: {{ this.search.accountcode }} - {{ this.search.catalogname }}</h3>
            </v-col>
        </v-row>
        <v-row class="mx-1 mt-2">
            <date-filter class="mt-3" ref="childRef"  @filtered-data="getAccountData" :dataToFilter="accountData" :search="this.search" :filterEndpoint="$API.accountingreports.getAccountingDetailData" />
            <v-btn class="btn-add" @click="onExportingToPdf()">Exportar a pdf</v-btn>
            <br/><br/><br/>
            <DxDataGrid 
            ref="dataGrid"
            :data-source="accountData" 
            @exporting="onExporting"
            :word-wrap-enabled="true" 
            :column-auto-width="true"
            :paging="{ enabled: false }" 
            style="max-height: 600px; width: 100%;"
            >   
                <DxScrolling 
                row-rendering-mode="virtual"  
                showScrollbar="always"
                useNative="false" />
                <DxColumn caption="ID de la Partida" cssClass="center-column" data-field="ID_Accounting_item" />
                <DxColumn caption="Fecha de la Partida" cssClass="center-column" data-field="accounting_entry_date" />
                <DxColumn caption="Concepto" cssClass="left-column" data-field="concept" />
                <DxColumn caption="Debe" data-field="Debe" cssClass="right-column" alignment="right" style="text-align: right !important;" />
                <DxColumn caption="Haber" data-field="Haber" cssClass="right-column" alignment="right" style="text-align: right !important;"/>
                <DxColumn caption="Saldo" data-field="balance" cssClass="right-column" alignment="right" style="text-align: right !important;" />
                <DxSummary>
                    <DxTotalItem
                    column="account_description"
                    display-format="RESUMEN DE TOTALES ------>"
                    />
                    <DxTotalItem
                    column="Debe"
                    summary-type="sum"
                    :value-format="customCurrencyFormat"
                    display-format="${0}"
                    cssClass="right-column"
                    
                    />
                    <DxTotalItem
                    column="Haber"
                    summary-type="sum"
                    :value-format="customCurrencyFormat"
                    display-format="${0}"
                   cssClass="right-column"
                    />
                    <DxTotalItem
                    column="balance"
                    summary-type="sum"
                    :value-format="customCurrencyFormat"
                    display-format="${0}"
                    cssClass="right-column"
                    
                    />
                </DxSummary>
                <DxExport :enabled="true" :formats="['xlsx','pdf']" />
            </DxDataGrid>
        </v-row>

        <v-dialog v-model="showAccountingForm" scrollable width="70%">
            <ExpenseDialog
            :win="win"
            :newAccountList="accountList"
            :newSelectedExpense="selectedExpense"
            :action="action"
            @handleAction="handleAction"
            />
        </v-dialog>

        <alerts
        style="z-index: 20000 !important"
        v-if="alert.show"
        v-on:close_alert="closeAlert"
        v-on:accept_alert="acceptAlert"
        :properties="alert"
        >
        </alerts>
    </div>
</template>

<script>
import Block from "@/components/Block";
import { DxDataGrid, DxScrolling, DxColumn, DxSummary, DxTotalItem, DxExport } from "devextreme-vue/data-grid";
import DateFilter from '@/components/DateFilter.vue';
import ExpenseDialog from "../ExpenseDialog.vue";
import { jsPDF } from 'jspdf'
import 'jspdf-autotable';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import { printToExcel } from "@/helpers/printToexcel";
import Alerts from "@/components/Alerts";

export default {
	name: "BalanceDetailByAccount",
	components: {
		Block,
        DxDataGrid,
        DxScrolling,
        DateFilter,
        ExpenseDialog,
        DxColumn,
        DxSummary,
        DxTotalItem,
        DxExport, 
        Alerts
	},
	props: ['win'],
	data() {
		return {
            loading: false,
            search: {},
            accountData: [],
            accountList: {},
            selectedExpense: {
                ExpenseName: null,
                ID: null,
                CatalogName: null,
            },
            showAccountingForm: false,
            action: {
                ADD: true,
                EDIT: false,
                DEL: false,
            },
            expenseIdCounter: 1,
            alert: {
                type: "success",
                show: false,
                header: "",
                body: "",
            },
        }
    },
    mounted() {
        this.getAllAcounts();
    },
    watch: {
    },
    methods: {
        customCurrencyFormat(value) {
            if (value == null) return ''; 
            return parseFloat(value).toFixed(2); 
        },
        async handleAction({ action, ...data } = {}) {
            this.showAccountingForm = false
            if(action === "error") {
                this.showAlert("warning", "Advertencia", "Código contable seleccionado es invalido.")
            }
            else {
                this.search.accountcode = data.AccountCode
                this.search.catalogname = data.CatalogName
                this.loading = true
                await this.$nextTick();

                this.$refs.childRef.filterData();
                
                
            }
        },
        getAllAcounts() {
            try {
                this.$API.branchaccountcatalog
                .getActiveBranchAccountCatalog()
                .then(({ data }) => {
                    this.accountList = data;
                });
            } catch (error) {
                console.log(error);
            }
        },
        getAccountData(filteredData) {
            this.accountData = filteredData
            this.loading = false
        },
        onExporting(e) {
            //let array = null;
            //array = ["saldo_anterior"]
            printToExcel(e, "Reporte de ventas");
        },
        onExportingToPdf() {
            const doc = new jsPDF(); // Crear PDF en modo horizontal
            
            const lastPoint = { x: 0, y: 0 };

            exportDataGrid({
                jsPDFDocument: doc,
                component: this.$refs["dataGrid"].instance,
                topLeft: { x: 0, y: 10 },
                font: 9,
                columnWidths: [25, 17.50, 90, 15, 15, 15],
                customizeCell: function (options) {
                    options.pdfCell.font.size = 7;
                },
                customDrawCell({ rect }) {
                    if (lastPoint.x < rect.x + rect.w) {
                        lastPoint.x = rect.x + rect.w;
                    }
                    if (lastPoint.y < rect.y + rect.h) {
                        lastPoint.y = rect.y + rect.h;
                    }
                },
            }).then(() => {
                var pageCount = doc.internal.getNumberOfPages();
                for(var i = 0; i < pageCount; i++){
                    doc.setPage(i);
                    let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
                    doc.setFontSize(7)
                    doc.text ('page:  ' + pageCurrent + ' / ' + pageCount,10,10);

                    const header = 'Balance de Detalle por Cuenta';
                    const pageWidth = doc.internal.pageSize.getWidth();
                    const headerWidth = doc.getTextDimensions(header).w;
                    doc.setFontSize(10);
                    doc.text(header, (pageWidth - headerWidth) / 2, 10);
                }
                doc.save('Reporte de balance de detalle por cuenta.pdf'); // Guardar el archivo PDF
            }).catch(error => {
                console.error('Error durante la exportación a PDF:', error); // Manejo de errores
            });
        },
        closeAlert() {
            this.alert.show = false;
        },
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
        },   
    }
}
</script>

<style>
  .dx-datagrid-headers .dx-datagrid-table .dx-row .right-column {
    text-align: center !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row .left-column {
    text-align: center !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row .center-column {
    text-align: center !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-data-row .right-column {
    text-align: right !important;
    font-size: 10px;
}

.dx-datagrid-content .dx-datagrid-table .dx-data-row .left-column {
    text-align: left !important;
    font-size: 10px;
}

.dx-datagrid-content .dx-datagrid-table .dx-data-row .center-column {
    text-align: center !important;
    font-size: 10px;
}

</style>